import {
	tap,
	filter,
	take,
	takeUntil,
	timeout,
	catchError,
} from "rxjs/operators";
import { IWorkOrderType, StatusType } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { BehaviorSubject, EMPTY, firstValueFrom } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
	AfterViewInit,
} from "@angular/core";
import { WorkOrderTypeDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { Globals } from "app/shared/modules/globals/globals.service";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import { TranslocoService } from "@jsverse/transloco";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "app/modules/auth/services/api.service";

@Component({
	selector: "work-order-type-update",
	template: `
		<div class="ui grid dirty-mackenzie" *transloco="let t">
			<div class="sixteen wide column">
				<div class="left floated left aligned eight wide column">
					<button class="ui labeled icon button" (click)="closeSidenav()">
						<i class="left arrow icon"></i>
						{{ t("word_cancel") }}
					</button>
				</div>
			</div>
			<div class="sixteen wide column">
				<h2>
					{{
						form_title_translation_key
							? t(form_title_translation_key, form_title_translation_params)
							: form_title
					}}
					<span>{{ title_postfix }}</span>
				</h2>
			</div>

			<div *ngIf="subscriptions_enabled$ | async" class="sixteen wide column">
				<subscription [topic]="topic"></subscription>
				<es-info-icon [infoText]="infoText"></es-info-icon>
			</div>

			<div class="sixteen wide column">
				<sf-form
					[schema]="schema"
					[model]="model"
					(onChange)="onChanges($event.value); model = $event.value"
					[validators]="validators"
					(isValid)="valid($event)"
					[bindings]="fieldBindings"
				></sf-form>
				<sf-form
					*ngIf="secondarySchema"
					[schema]="secondarySchema"
					[model]="secondaryModel"
					(onChange)="secondaryModel = $event.value"
					(isValid)="secondaryValid($event)"
				></sf-form>
				<sf-form
					*ngIf="statusSchema"
					[schema]="statusSchema"
					[model]="statusModel"
					(onChange)="statusModel = $event.value"
				></sf-form>
				<span *ngIf="schema.warning" class="warningMessage">
					{{
						schema.warning_translation_key
							? t(schema.warning_translation_key)
							: schema.warning
					}}
				</span>
			</div>
			<br />

			<div class="sixteen wide column" *ngIf="error$ | async">
				<div class="ui error message">
					<span>{{ error$ | async }}</span>
				</div>
			</div>

			<div class="sixteen wide column" *ngIf="submit_button !== undefined">
				<submit-button
					[loading]="loading$ | async"
					[valid]="
						(valid$ | async) && (!secondarySchema || (secondaryValid$ | async))
					"
					[submit_icon]="submit_icon"
					[submit_button]="submit_button"
					[submit_button_translation_key]="submit_button_translation_key"
					(onSubmit)="onSubmit()"
				/>
			</div>
		</div>
	`,
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderTypeUpdateComponent
	extends GenericUpdateComponent<IWorkOrderType>
	implements OnInit, OnDestroy, AfterViewInit
{
	work_order_type$ = this._store.select(fromDashboard.getSelectedWorkOrderType);
	work_order_type: IWorkOrderType;

	public schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Work Order Type name already in use.",
				warning_translation_key: marker(
					"form_field_warning_work_order_type_name_already_in_use",
				),
			},
			subscribed_users: {
				type: "array",
				widget: "data-select",
				title: "Subscribed Users",
				title_translation_key: marker("form_field_label_subscribed_users"),
				info_text:
					"Any subscribed users will receive notifications when work orders of this type are closed.",
				quick_create: false,
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "users",
							value_key: "id",
							disabled: true,
							queryString: {
								active_filter: "ACTIVE",
							},
						},
					],
				},
			},
			archive_remaining_plants: {
				type: "boolean",
				widget: "checkbox",
				title: "Archive remaining plants in work order when it is closed.",
				title_translation_key: marker(
					"form_field_label_archive_remaining_plants_in_work_order_when_it_is_closed",
				),
				default: true,
			},
			show_processing_loss: {
				type: "boolean",
				widget: "checkbox",
				title: "Show processing loss.",
				title_translation_key: marker("form_field_label_show_processing_loss"),
				default: true,
			},
			processing_loss_inventory_product_ids: {
				type: "array",
				title: "Processing Loss Inventory Products",
				title_translation_key: marker(
					"form_field_label_processing_loss_inventory_products",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_products",
							queryString: {
								showArchived: "false",
							},
						},
					],
				},
				visibleIf: {
					show_processing_loss: [true],
				},
			},
			add_processing_loss_warning: {
				type: "boolean",
				widget: "checkbox",
				title: "Add Processing Loss Warning",
				title_translation_key: marker(
					"form_field_label_add_processing_loss_warning",
				),
				default: false,
				visibleIf: {
					show_processing_loss: [true],
				},
			},
			processing_loss_warning_upper_bound: {
				type: "number",
				widget: "number",
				title: "Upper bound (%)",
				title_translation_key: marker("form_field_label_upper_bound"),
				visibleIf: {
					add_processing_loss_warning: [true],
				},
			},
			processing_loss_warning_lower_bound: {
				type: "number",
				widget: "number",
				title: "Lower bound (%)",
				title_translation_key: marker("form_field_label_lower_bound"),
				visibleIf: {
					add_processing_loss_warning: [true],
				},
			},
			use_remaining_input: {
				type: "boolean",
				widget: "checkbox",
				title: "Work Order Inputs default to Use Remaining.",
				title_translation_key: marker(
					"form_field_label_work_order_inputs_default_to_use_remaining",
				),
				default: false,
			},
			use_work_order_location_output: {
				type: "boolean",
				widget: "checkbox",
				title: "Work Order Outputs default to Work Order Location.",
				default: false,
			},
			use_existing_inventory_output: {
				type: "boolean",
				widget: "checkbox",
				title: "Work Order Outputs +1 default to Use Existing Inventory.",
				title_translation_key: marker(
					"form_field_label_work_order_outputs_plus_one_default_to_use_existing_inventory",
				),
				description: `If unchecked, it will default to Create New Inventory.`,
				description_translation_key: marker(
					"form_field_description_if_unchecked_it_will_default_to_create_new_inventory",
				),
				default: false,
			},
			use_existing_lot_output: {
				type: "boolean",
				widget: "checkbox",
				title: "Work Order Outputs +1 default to Use Existing Lot.",
				title_translation_key: marker(
					"form_field_label_work_order_outputs_plus_one_default_to_use_existing_lot",
				),
				description: `If unchecked, it will default to Create New Lot.`,
				description_translation_key: marker(
					"form_field_label_if_unchecked_it_will_default_to_create_new_lot",
				),
				default: false,
			},
			add_equipment_after_close: {
				type: "boolean",
				widget: "checkbox",
				title: "Add or Remove Equipment on Work Order after Close.",
				title_translation_key: marker(
					"form_field_label_add_or_remove_equipment_on_work_order_after_close",
				),
				default: false,
			},
			add_equipment_after_lock: {
				type: "boolean",
				widget: "checkbox",
				title: "Add or Remove Equipment on Work Order after Lock.",
				title_translation_key: marker(
					"form_field_label_add_or_remove_equipment_on_work_order_after_lock",
				),
				default: false,
			},
			add_record_after_close: {
				type: "boolean",
				widget: "checkbox",
				title: "Add or Remove Record on Work Order after Close.",
				title_translation_key: marker(
					"form_field_label_add_or_remove_record_on_work_order_after_close",
				),
				default: false,
			},
			add_record_after_lock: {
				type: "boolean",
				widget: "checkbox",
				title: "Add or Remove Record on Work Order after Lock.",
				title_translation_key: marker(
					"form_field_label_add_or_remove_record_on_work_order_after_lock",
				),
				default: false,
			},
			require_lot_for_destruction: {
				type: "boolean",
				widget: "checkbox",
				title: "Require Lot for Work Order Destruction.",
				title_translation_key: marker(
					"form_field_require_lot_on_work_order_destruction",
				),
				default: false,
			},
			duplicate_timestamp: {
				type: "boolean",
				widget: "checkbox",
				title: "Work Order timestamp is duplicated on +1.",
				description: `If unchecked, timestamp will not be duplicated.`,
				default: false,
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
				description: "archive this type so that it cannot be used in a work order.",
				description_translation_key: marker(
					"form_field_description_archive_this_type_so_that_it_cannot_be_used_in_a_work_order",
				),
			},
			default_printer_job_template_id: {
				type: "number",
				widget: "data-select",
				title: "Default Printer Job Template",
				title_translation_key: marker(
					"form_field_label_default_printer_job_template",
				),
				oneOf: [
					{
						result_type: "printer_job_templates",
					},
				],
			},
			default_form_schema_uuids: {
				type: "array",
				widget: "data-select",
				title: "Related Records",
				title_translation_key: marker("form_field_label_default_form_schemas"),
				quick_create: false,
				items: {
					type: "string",
					oneOf: [
						{
							result_type: "schemas",
							value_key: "type",
							queryString: {
								recordFilter: "RELEASED",
							},
						},
					],
				},
			},
			record_pdfs_follow_related_record_order: {
				type: "boolean",
				widget: "checkbox",
				title: "Record PDFs follow Related Record Order",
				default: false,
				title_translation_key: marker(
					"form_field_label_record_pdfs_follow_related_record_order",
				),
				info_text: this._translocoService.translate(
					"form_field_info_record_pdfs_follow_related_record_order",
				),
			},
			required_form_schema_uuids: {
				type: "array",
				widget: "data-select",
				title: "Required Records",
				title_translation_key: marker("form_field_label_required_form_schemas"),
				quick_create: false,
				items: {
					type: "string",
					oneOf: [
						{
							result_type: "schemas",
							value_key: "type",
							queryString: {
								recordFilter: "RELEASED",
							},
						},
					],
				},
			},
			related_equipment_ids: {
				type: "array",
				widget: "data-select",
				title: "Related Equipment",
				title_translation_key: marker("form_field_label_related_equipment"),
				quick_create: false,
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "equipments",
						},
					],
				},
			},
			required_equipment_ids: {
				type: "array",
				widget: "data-select",
				title: "Required Equipment",
				title_translation_key: marker("form_field_label_required_equipment"),
				quick_create: false,
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "equipments",
						},
					],
				},
			},
			inventory_products_sources_outputs_section: {
				type: "string",
				widget: "section",
				title: "Source/Outputs Related to Work Orders",
				title_translation_key: marker(
					"form_field_label_source_outputs_related_to_work_orders",
				),
			},
			inventory_category_source_ids: {
				type: "array",
				title: "Allowed Inventory Categories for Inputs",
				title_translation_key: marker(
					"form_field_label_allowed_inventory_categories_for_inputs",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_categories",
							queryString: {
								showArchived: "false",
							},
						},
					],
				},
			},
			inventory_category_output_ids: {
				type: "array",
				title: "Allowed Inventory Categories for Outputs",
				title_translation_key: marker(
					"form_field_label_allowed_inventory_categories_for_outputs",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_categories",
							queryString: {
								showArchived: "false",
							},
						},
					],
				},
			},
			inventory_category_destruction_ids: {
				type: "array",
				title: "Allowed Inventory Categories for Destruction",
				title_translation_key: marker(
					"form_field_label_allowed_inventory_categories_for_destruction",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_categories",
							queryString: {
								internal_vendor: "true",
								showArchived: "false",
							},
						},
					],
				},
			},
			inventory_product_source_ids: {
				type: "array",
				title: "Allowed Inventory Products for Inputs",
				title_translation_key: marker(
					"form_field_label_allowed_inventory_products_for_inputs",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_products",
							queryString: {
								showArchived: "false",
							},
						},
					],
				},
			},
			inventory_product_output_ids: {
				type: "array",
				title: "Allowed Inventory Products for Outputs",
				title_translation_key: marker(
					"form_field_label_allowed_inventory_products_for_outputs",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_products",
							queryString: {
								showArchived: "false",
							},
						},
					],
				},
			},
			only_allow_destruction_of_used_products: {
				type: "boolean",
				widget: "checkbox",
				title:
					"Only allow destruction of products used in work order input/outputs",
				default: true,
				title_translation_key: marker(
					"form_field_label_only_allow_destruction_of_used_products",
				),
			},
			inventory_product_destruction_ids: {
				type: "array",
				title: "Allowed Inventory Products for Destruction",
				title_translation_key: marker(
					"form_field_label_allowed_inventory_products_for_destruction",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_products",
							queryString: {
								showArchived: "false",
							},
						},
					],
				},
				visibleIf: {
					only_allow_destruction_of_used_products: [false],
				},
			},
			output_inventory_status: {
				type: "boolean",
				widget: "checkbox",
				title: "Show Output Inventory Status",
				default: false,
				title_translation_key: marker("form_field_label_output_inventory_status"),
			},
			default_output_inventory_status_id: {
				type: "number",
				title: "Default Output Inventory Status",
				title_translation_key: marker(
					"form_field_label_default_output_inventory_status",
				),
				widget: "data-select",
				oneOf: [
					{
						result_type: "statuses",
						queryString: {
							status_type: StatusType.Inventory,
						},
					},
				],
				visibleIf: {
					output_inventory_status: [true],
				},
			},
			allow_plants_as_input: {
				type: "boolean",
				widget: "checkbox",
				title: "Plants allowed as work order input",
				title_translation_key: marker(
					"form_field_label_plants_allowed_as_work_order_input",
				),
				default: true,
			},
			allow_plants_as_output: {
				type: "boolean",
				widget: "checkbox",
				title: "Plants allowed as work order output",
				title_translation_key: marker(
					"form_field_label_plants_allowed_as_work_order_output",
				),
				default: true,
			},
			allow_plants_as_destruction: {
				type: "boolean",
				widget: "checkbox",
				title: "Plants allowed as work order destruction",
				title_translation_key: marker(
					"form_field_label_plants_allowed_as_work_order_destruction",
				),
				default: true,
			},
			section2: {
				type: "string",
				widget: "section",
				title: "Location Changes Related to Work Orders",
				title_translation_key: marker(
					"form_field_label_location_changes_related_to_work_orders",
				),
			},
			default_location_id: {
				type: "number",
				title: "Default work order location",
				title_translation_key: marker(
					"form_field_label_default_work_order_location",
				),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
			},
			allow_location_change: {
				type: "boolean",
				widget: "checkbox",
				title: "Allow default location to be changed by user.",
				title_translation_key: marker(
					"form_field_label_allow_default_location_to_be_changed_by_user",
				),
			},
			move_batch_to_location: {
				type: "boolean",
				widget: "checkbox",
				title: "Move batch to work order location",
				title_translation_key: marker(
					"form_field_label_move_batch_to_work_order_location",
				),
				description: `This will check the "Move batch to work order location" box by default on all inputs to the work order. This will only apply if a location is given for the work order.`,
				description_translation_key: marker(
					"form_field_description_this_will_check_the_move_batch_to_work_order_location_box_by_default_on_all_inputs_to_the_work_order",
				),
			},
			location_change_on_close: {
				type: "boolean",
				widget: "checkbox",
				title: "Force location change on close",
				title_translation_key: marker(
					"form_field_label_force_location_change_on_close",
				),
				description:
					"Require destination location for batch(es) when closing work order.",
				description_translation_key: marker(
					"form_field_description_require_destination_location_for_batches_when_closing_work_order",
				),
				// readOnly: true,
			},
		},
		required: ["name"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	gi_enabled = false;
	ez_gi_migrated = false;
	has_quality_records = false;

	subscriptions_enabled$ = new BehaviorSubject<boolean>(false);
	subscribed_users$ = new BehaviorSubject<number[]>([]);

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
		private readonly _itemService: ItemService,
		private readonly _http: HttpClient,
		private readonly _api: ApiService,
		private readonly _injector: Injector,
		private readonly _devCycleService: DevCycleService,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store, _cd);
		this.form_title = "Update a Work Order Type";
		this.form_title_translation_key = marker(
			"form_title_update_a_work_order_type",
		);
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
		this.gi_enabled = this._injector.get("gi_enabled", false);
		this.ez_gi_migrated = this._injector.get("ez_gi_migrated", false);
		this.has_quality_records = this._injector.get("has_quality_records", false);

		if (!this.gi_enabled || this.ez_gi_migrated) {
			delete this.schema.properties.use_work_order_location_output;
			delete this.schema.properties.use_existing_inventory_output;
			delete this.schema.properties.use_existing_lot_output;
		}

		if (this._globals.gmp_enabled) {
			delete this.schema.properties.duplicate_timestamp;
		}
		this._devCycleService
			.getVariable(DevCycleKey.WorkOrderOutputInventoryStatus, false)
			.subscribe((variable: any) => {
				if (!variable.value) {
					if (this.gi_enabled && !this.ez_gi_migrated) {
						delete this.schema.properties.output_inventory_status;
						delete this.schema.properties.default_output_inventory_status_id;
						this._cd.detectChanges();
					}
				}
			});
	}

	ngAfterViewInit() {
		this._devCycleService
			.getVariable(DevCycleKey.SubscriptionsEnabled, false)
			.subscribe((variable: any) => {
				if (variable.value) {
					this.subscriptions_enabled$.next(true);
				} else {
					this.subscriptions_enabled$.next(false);
					this.schema.properties.subscribed_users.hidden = true;
					this._cd.detectChanges();
				}
			});
		this.subscribed_users$.subscribe((userIds) => {
			this.model = {
				...this.model,
				subscribed_users: userIds,
			};
			this._cd.detectChanges();
		});
		this.fetchSubscriptionStatus(this.topic);
	}

	get topic(): string {
		return `work_order.${this.work_order_type.id}.closed`;
	}

	get infoText(): string {
		return this._translocoService.translate(
			"work_order_type_subscription_info_text",
		);
	}

	ngOnInit() {
		// Remove the section and multiselects for non gi, only gi allows user to specify
		// what types of inputs and outputs are allowed in work orders of this type
		if (!this.gi_enabled || this.ez_gi_migrated) {
			delete this.schema.properties.processing_loss_inventory_product_ids;
			delete this.schema.properties.inventory_category_source_ids;
			delete this.schema.properties.inventory_category_output_ids;
			delete this.schema.properties.inventory_category_destruction_ids;
			delete this.schema.properties.inventory_product_source_ids;
			delete this.schema.properties.inventory_product_output_ids;
			delete this.schema.properties.only_allow_destruction_of_used_products;
			delete this.schema.properties.inventory_product_destruction_ids;
			delete this.schema.properties.inventory_products_sources_outputs_section;
			delete this.schema.properties.output_inventory_status;
			delete this.schema.properties.default_output_inventory_status_id;
			delete this.schema.properties.require_lot_for_destruction;
		}

		if (!this.has_quality_records) {
			delete this.schema.properties.add_record_after_close;
			delete this.schema.properties.add_record_after_lock;
			delete this.schema.properties.default_form_schema_uuids;
			delete this.schema.properties.record_pdfs_follow_related_record_order;
			delete this.schema.properties.required_form_schema_uuids;
		}

		this.work_order_type$
			.pipe(
				takeUntil(this.destroyed$),
				filter(
					(work_order_type): work_order_type is IWorkOrderType =>
						work_order_type !== undefined,
				),
				take(1),
			)
			.subscribe((work_order_type) => {
				this.work_order_type = work_order_type;
				this.model.id = this.work_order_type.id;
				this.model.name = this.work_order_type.name;
				this.model.default_location_id = this.work_order_type.default_location_id;
				this.model.allow_location_change =
					this.work_order_type.allow_location_change;
				this.model.archive_remaining_plants =
					this.work_order_type.archive_remaining_plants;
				this.model.show_processing_loss = this.work_order_type.show_processing_loss;
				this.model.add_processing_loss_warning =
					this.work_order_type.add_processing_loss_warning;
				this.model.processing_loss_warning_upper_bound =
					this.work_order_type.processing_loss_warning_upper_bound;
				this.model.processing_loss_warning_lower_bound =
					this.work_order_type.processing_loss_warning_lower_bound;
				this.model.use_remaining_input = this.work_order_type.use_remaining_input;
				this.model.use_work_order_location_output =
					this.work_order_type.use_work_order_location_output;
				this.model.use_existing_inventory_output =
					this.work_order_type.use_existing_inventory_output;
				this.model.use_existing_lot_output =
					this.work_order_type.use_existing_lot_output;
				this.model.add_equipment_after_close =
					this.work_order_type.add_equipment_after_close;
				this.model.add_equipment_after_lock =
					this.work_order_type.add_equipment_after_lock;
				this.model.add_record_after_close =
					this.work_order_type.add_record_after_close;
				this.model.add_record_after_lock =
					this.work_order_type.add_record_after_lock;
				this.model.archived = this.work_order_type.archived;
				this.model.move_batch_to_location =
					this.work_order_type.move_batch_to_location;
				this.model.location_change_on_close =
					this.work_order_type.location_change_on_close;
				this.model.duplicate_timestamp = this.work_order_type.duplicate_timestamp;
				this.model.default_printer_job_template_id =
					this.work_order_type.default_printer_job_template_id;
				this.model.default_form_schema_uuids =
					this.work_order_type.default_form_schema_uuids;
				this.model.record_pdfs_follow_related_record_order =
					this.work_order_type.record_pdfs_follow_related_record_order;
				this.model.required_form_schema_uuids =
					this.work_order_type.required_form_schema_uuids;
				this.model.related_equipment_ids =
					this.work_order_type.related_equipment_ids;
				this.model.required_equipment_ids =
					this.work_order_type.required_equipment_ids;

				if (this._globals.general_inventory_enabled) {
					this.model.inventory_category_source_ids =
						this.work_order_type.inventory_category_sources?.map(
							(source) => source.id,
						);
					this.model.inventory_category_output_ids =
						this.work_order_type.inventory_category_outputs?.map(
							(output) => output.id,
						);
					this.model.inventory_category_destruction_ids =
						this.work_order_type.inventory_category_destructions?.map(
							(output) => output.id,
						);
					this.model.processing_loss_inventory_product_ids =
						this.work_order_type.processing_loss_inventory_products?.map(
							(product) => product.id,
						);
					this.model.inventory_product_source_ids =
						this.work_order_type.inventory_product_sources?.map(
							(source) => source.id,
						);
					this.model.inventory_product_output_ids =
						this.work_order_type.inventory_product_outputs?.map(
							(output) => output.id,
						);
					this.model.only_allow_destruction_of_used_products =
						this.work_order_type.only_allow_destruction_of_used_products;
					this.model.inventory_product_destruction_ids =
						this.work_order_type.inventory_product_destructions?.map(
							(output) => output.id,
						);
					this.model.output_inventory_status =
						this.work_order_type.output_inventory_status;
					this.model.default_output_inventory_status_id =
						this.work_order_type.default_output_inventory_status_id;
					this.model.allow_plants_as_input =
						this.work_order_type.allow_plants_as_input;
					this.model.allow_plants_as_output =
						this.work_order_type.allow_plants_as_output;
					this.model.allow_plants_as_destruction =
						this.work_order_type.allow_plants_as_destruction;

					this.model.require_lot_for_destruction =
						this.work_order_type.require_lot_for_destruction;
				}

				if (
					this.work_order_type.name === "Harvest" ||
					this.work_order_type.name === "Drying"
				) {
					this.schema.properties.name.readOnly = true;
					// delete this.schema.properties.move_batch_to_location.readOnly;
					// delete this.schema.properties.location_change_on_close.readOnly;
				}
				this._cd.detectChanges();
				this.getNames();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	// Used for schema validation - duplicate Work Order Type name check
	getNames() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`work_order_types/fetch/names`, "", {
				col: "name",
				except: this.model.name,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	updateItem(formData: any) {
		const { subscribed_users, ...work_order } = formData;
		this.loading$.next(true);
		if (this.subscriptions_enabled$.value) {
			this.updateSubscriptionsToTopic(this.topic, subscribed_users)
				.then(() => {
					// Success?
				})
				.catch((error) => {
					this.error$.next(handleObservableError(error, true));
				});
		}
		this._itemService
			.update(
				`work_order_type`,
				Number(work_order.id),
				work_order,
				WorkOrderTypeDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_order_types",
						}),
					);

					// Re pull facility so that updated list of work order types end up in globals
					if (updatedItem.facility_id) {
						this._store.dispatch(
							SelectionActions.select({
								result_type: "facilities",
								id: updatedItem.facility_id,
							}),
						);
					}

					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	/**
	 * Fetch the subscribed users for a given topic
	 * @param topic - The topic to fetch the subscribed users for
	 * @returns A promise that resolves to the subscribed users
	 *
	 * GET /subscribers/bulk?topic=work_order.[work_order_type_id].closed
	 *
	 *{
	 *	 "userIds": [1, 2, 3]
	 *}
	 */
	fetchSubscriptionStatus = async (topic: string) => {
		const response = await firstValueFrom(
			this._http.get<{ userIds?: number[] }>(
				`${this._api.es_api_server}/subscribers/bulk?topic=${topic}`,
			),
		);
		if (response?.userIds) {
			this.subscribed_users$.next(response.userIds);
		} else {
			this.subscribed_users$.next([]);
		}
		return response;
	};

	/**
	 * Update the subscribed users for a given topic
	 * @param topic - The topic to update the subscribed users for
	 * @param userIds - The user ids to update the subscribed users
	 * @returns A promise that resolves to the response from the server
	 *
	 * PATCH /subscribers/bulk?topic=work_order.[work_order_type_id].closed
	 * {
	 *	 "userIds": [1, 2, 3]
	 * }
	 */
	updateSubscriptionsToTopic = (
		topic: string,
		userIds: number[],
	): Promise<any> => {
		return firstValueFrom(
			this._http.patch<any>(
				`${this._api.es_api_server}/subscribers/bulk?topic=${topic}`,
				{ userIds },
			),
		);
	};
}
